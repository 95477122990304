import {
    type FormErrors,
    type FormGlobalErrorValue,
    type FormObject,
    ModelAction,
    type FormDataObject,
    type FormFieldObject
} from '@core-types/form'
import type { ConstructorType } from '@composable-api-types/utils'
import type { input, output, ZodSchema } from 'zod'
import type { MaybePromise } from 'rollup'
import type { DeepReadonly, MaybeRefOrGetter, ModelRef } from 'vue'

/**
 * A composable used to create required data for modals that update a specific model.
 * It automatically creates the data for the form - which includes the entity model & the action to be executed.
 * Additionally, it offers a way to define texts for the modal in a simple way.
 *
 * @example
 * const { data: activeAddress, texts: addressModalTexts } = useModelAction({
 *     [ModelAction.CREATE]: {
 *         title: t('user.add_address'),
 *         buttonLabel: t('user.add_address'),
 *     },
 *     [ModelAction.EDIT]: {
 *         title: t('user.edit_address'),
 *         buttonLabel: t('user.save_address'),
 *     },
 *     [ModelAction.DELETE]: {
 *         title: t('user.delete_address'),
 *         buttonLabel: t('user.delete_address'),
 *     },
 * }, CustomerAddressModel)
 *
 * @param texts A custom reactive object with texts for the modal
 * @param model The model of the entity to be edited (sadly, I think this is necessary for type inference)
 * @todo find a way not to have to pass the model as a prop but also be able to infer the type of the texts object
 */
export function useModelAction<T extends ConstructorType<any>, U extends Record<ModelAction, any>>(texts: U, model: T) {
    const data = ref<{
        action: Exclude<ModelAction, ModelAction.CREATE>
        model: InstanceType<T>
    } | {
        action: ModelAction.CREATE
        model: null
    } | null>(null)

    const txt = computed(() => {
        return texts[(data.value?.action || ModelAction.CREATE) as keyof U]
    })


    return {
        data: data,
        texts: txt,
    }
}

export type FormResetOn = 'submit'
export type FormSubmitOrigin = 'change' | 'form-change' | 'submit'
export type FormAutoSubmitOn = 'change' | 'change-debounced' | 'form-change'
export type FormErrorType = 'all' | 'validation' | 'global'
export type FormOnSubmit<T extends ZodSchema> = (formData: output<T>) => MaybePromise<void>
export type FormInitialData<T extends ZodSchema> = Partial<AllowNullDeep<input<T>>>

export interface UseFormOptions<T extends ZodSchema> {
    schema: MaybeRefOrGetter<T>
    onSubmit: MaybeRefOrGetter<FormOnSubmit<T>>
    initialData?: MaybeRefOrGetter<FormInitialData<T> | undefined>
    /**
     * When to reset the form.
     * Currently, it is used as 'boolean style' prop, but it could be extended to support more options in the future.
     * If set to `'submit'`, the form will fully reset its data after a successful submission.
     *
     * @default undefined
     */
    resetOn?: MaybeRefOrGetter<FormResetOn | undefined>
    /**
     * When to auto-submit the form.
     * Auto-submitting doesn't validate the form.
     *
     * _The recommended way it to use the `'form-change'` option, which submits the form when the form is blurred,
     * rather than on every input change._

     * By default, the form requires manual submission.
     *
     * This value cannot be changed reactively and is only checked once.
     * @default undefined
     */
    autoSubmitOn?: MaybeRefOrGetter<FormAutoSubmitOn | undefined>
    /**
     * When to validate the form.
     * By default, the form is validated only on explicit submit.
     * @default 'submit'
     */
    validateOn?: MaybeRefOrGetter<FormSubmitOrigin | FormSubmitOrigin[] | undefined>
    /**
     * Whether to show a notification when form errors occur.
     * By default, no notifications are shown.
     *
     * The possible values are:
     * - `'all'` - either the first validation error or the global error message will be shown in a notification
     * - `'validation'` - only the first validation error will be shown in a notification
     * - `'global'` - only the global error message will be shown in a notification
     * @default undefined
     */
    notifyOnError?: MaybeRefOrGetter<FormErrorType | undefined>
}

export function useForm<T extends ZodSchema>(options: UseFormOptions<T>): FormObject<T> {
    const { notifyError } = useNotifications()
    const nuxtApp = useNuxtApp()

    const schema = computed(() => toValue(options.schema))
    const fieldErrors = ref<FormErrors<T>>({})
    const globalErrorMessage = ref<FormGlobalErrorValue>(null)
    const isSubmitting = ref<boolean>(false)
    let beforeSubmitCallback: (() => MaybePromise<boolean | undefined>) | undefined

    let [
        _formData,
        _formValues,
    ] = generateFormDataObjects(schema.value, toValue(options.initialData), { convertNullToUndefined: true })
    const formDataObject = ref(_formData) as Ref<FormDataObject<T>>
    const formValuesObject = ref(_formValues)

    watch(() => toValue(options.schema), (newSchema) => {
        [
            _formData,
            _formValues,
        ] = generateFormDataObjects(newSchema, formValuesObject.value, { convertNullToUndefined: true })

        formDataObject.value = _formData
        formValuesObject.value = _formValues.value
    })

    watch(() => toValue(options.initialData), (newData) => {
        mergeFormValues(formValuesObject, newData)
    })

    function resetFormData(ignoreInitialData: boolean = false) {
        mergeFormValues(formValuesObject, ignoreInitialData ? {} : toValue(options.initialData), {
            fallbackToCurrentValues: false,
        })
    }

    function resetErrors() {
        fieldErrors.value = {}
        globalErrorMessage.value = null
    }

    function resetForm(ignoreInitialData: boolean = false) {
        resetFormData(ignoreInitialData)
        resetErrors()
    }

    function setFieldErrors(errors: FormErrors<T>) {
        fieldErrors.value = errors
    }

    function resetFieldError(field: keyof input<T>) {
        fieldErrors.value[field] = null
    }

    function setGlobalError(error: FormGlobalErrorValue) {
        globalErrorMessage.value = error
    }

    function _getFirstError(options?: Partial<{ fallbackToGlobal: boolean, includeFieldName: boolean }>): string | null {
        const validationError: string | [string, string] | undefined = options?.includeFieldName
            ? (Object.entries(fieldErrors.value)).find(([_, error]) => error !== null) as [string, string] | undefined
            : Object.values(fieldErrors.value).find(Boolean) as string | undefined

        return validationError
            ? Array.isArray(validationError) ? `[${validationError[0]}]: ${validationError[1]}` : validationError
            : options?.fallbackToGlobal ? globalErrorMessage.value : null
    }

    async function validateForm(): Promise<boolean> {
        const result = toValue(schema).safeParse(formValuesObject.value)

        if (!result.success) {
            const errors: FormErrors<T> = {}
            for (const issue of result.error.issues) {
                let path = ''
                for (const key of issue.path) {
                    path += `${path.length ? '.' : ''}${key}`
                }

                if (errors[path as keyof typeof errors]) continue
                errors[path as keyof typeof errors] = issue.message
            }

            setFieldErrors(errors)

            if (import.meta.dev) {
                warnLog('[useForm]: The form has validation errors and wasn\'t submitted.', errors)
            }

            // error notifications
            if (toValue(options.notifyOnError) === 'validation' || toValue(options.notifyOnError) === 'all') {
                const message = _getFirstError()
                if (message) notifyError(message)
            }

            return false
        }

        resetErrors()
        return true
    }

    async function handleSubmit(origin: FormSubmitOrigin = 'submit') {
        const validateOn = toValue(options.validateOn)
        // validate form
        if (Array.isArray(validateOn) ? validateOn.includes(origin) : validateOn === origin) {
            const validationResult = validateForm()
            if (!validationResult) return
        }

        // handle before submit callback
        if (beforeSubmitCallback) {
            const shouldSubmit = await beforeSubmitCallback()
            if (shouldSubmit === false) return
        }

        // submit form
        try {
            isSubmitting.value = true
            await toValue(options.onSubmit)(formValuesObject.value)

            if (toValue(options.resetOn) === 'submit') {
                resetFormData()
            }
            resetErrors()
        } catch (e) {
            if (e instanceof ApiResponseError) {
                setFieldErrors(e.getValidationErrorsByField() as FormErrors<T>)
            } else {
                let wasErrorSet = false
                // TODO: use instanceof when the following issue is fixed:
                // https://github.com/nuxt/nuxt/issues/25747
                if (typeof e === 'object' && e !== null && 'data' in e && typeof e.data === 'object' && e.data !== null) {
                    if (
                        'data' in e.data && typeof e.data.data === 'object' && e.data.data !== null
                        && 'validationErrorsByField' in e.data.data && typeof e.data.data.validationErrorsByField === 'object' && e.data.data.validationErrorsByField !== null
                    ) {
                        setFieldErrors(e.data.data.validationErrorsByField)
                        wasErrorSet = true
                    }

                    if ('message' in e.data && typeof e.data.message === 'string') {
                        setGlobalError(e.data.message)
                        wasErrorSet = true
                    }
                }

                if (!wasErrorSet) {
                    setGlobalError(nuxtApp.$i18n.t('_core_simploshop.labels.unknown_error'))
                    console.error(e)
                }
            }

            // error notifications
            if (toValue(options.notifyOnError)) {
                const message = toValue(options.notifyOnError) !== 'global'
                    ? _getFirstError({ fallbackToGlobal: toValue(options.notifyOnError) === 'all', includeFieldName: true })
                    : globalErrorMessage.value
                if (message) notifyError(message)
            }
        }
        finally {
            isSubmitting.value = false
        }
    }

    return {
        schema: schema,
        globalErrorMessage: readonly(globalErrorMessage),
        isSubmitting: readonly(isSubmitting),

        formDataObject: formDataObject,
        formValues: formValuesObject,
        formFieldErrors: readonly(fieldErrors) as DeepReadonly<Ref<FormErrors<T>>>,

        setFieldErrors: setFieldErrors,
        setGlobalError: setGlobalError,
        resetFieldError: resetFieldError,
        resetForm: resetForm,
        resetFormData: resetFormData,
        validateForm: validateForm,

        _private: {
            _handleSubmit: handleSubmit,
            _callBeforeSubmit: (callback: () => MaybePromise<boolean | undefined>) => {
                beforeSubmitCallback = callback
            },
            _autoSubmitOn: options.autoSubmitOn,
            _globalErrorMessage: computed(() => globalErrorMessage.value),
            _fieldErrors: readonly(fieldErrors) as DeepReadonly<Ref<FormErrors<T>>>,
        },
    }
}

export function useFormData() {
    const { injected } = useCoreUiFormProvide<any>()

    function setFieldObjectValue<T extends FormFieldObject<any>>(field: T | ModelRef<T | undefined> | undefined, value: T['__v']) {
        const changeEvent = setFormFieldObjectValue(field, value)
        if (changeEvent) injected.bus?.emit(changeEvent)
    }

    return {
        setFieldObjectValue,
    }
}
